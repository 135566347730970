* {
  box-sizing: border-box;
}


body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
  /* background: white url("pexels-pixabay-40896.jpg") repeat right top; */
  background-size: cover;
  max-width: 100%;
  overflow-x: hidden;
}

button {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  background-color: rgb(58, 54, 55);
  transform: scale(1);
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 50px;
  height: 50px;

}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.game {
  display: flex;
  flex-direction: column;

}

#root {
  /* border: thin solid black; */
  border-radius: 10px;
  box-shadow: 10px 10px 50px black;
  width: 500px;
  backdrop-filter: blur(10px);
}

@media only screen and (max-width: 600px) {
  #root {
    width: 100%;
  }
}

.game-board {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.board-head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  margin: 10px;
  border: thin solid black;
  padding: 10px;
  border-radius: 10px;
  background-color: skyblue;
}
.reset {
  border: thin solid black;
  padding: 5px;
  font-size: 20px;
  border-radius: 50%;
  background-color: blue;
  cursor: pointer;
}

.game-info {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.win-box {
  /* border: thin solid green; */
  box-shadow: 0 0 5px green;
  background-color: green;
}